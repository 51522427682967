import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/SeoComponent';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Footer from '../components/FooterNew';
import { Box, Container, Grid } from '@mui/material';
import Mainnavigation from '../components/NavigationNew';
import { navigate } from 'gatsby';
import Share from '../assets/share.svg';
import Back from '../assets/back.svg';
import MiniAdTemplate from './MiniAdTemplate';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

// unused options
const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            return (
                <>
                    <h2>Embedded Asset</h2>
                    <pre>
                        <code>{JSON.stringify(node, null, 2)}</code>
                    </pre>
                </>
            );
        },
    },
};

class SinglePageTemplate extends React.Component {
    render() {
        const share = true;
        const handleShareBtn = () => {
            if (navigator.share && (navigator.userAgent || navigator.vendor)) {
                navigator
                    .share({
                        url: document.location.href,
                    })
                    .then(() => {})
                    .catch(() => {});
            }
        };

        var post = '';

        if (this.props.pageContext.brand === 'Apple') {
            post = get(this.props, 'data.appleArticleData');
        } else {
            post = get(this.props, 'data.samsungArticleData');
        }
        console.log(this.props);

        const metaTitle = post.metaTags && post.metaTags.title ? post.metaTags.title : '';
        const metaDescription = post.metaTags && post.metaTags.description ? post.metaTags.description : '';
        const ogImage = post.metaTags && post.metaTags.ogImage ? post.metaTags.ogImage.file.url : '';

        let date = new Date(post.publishedDate);
        const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'Asia/Manila' };

        // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
        const domainUrl = '';

        const canonicalUrl =
            this.props && this.props.pageContext && this.props.pageContext.canonicalPath
                ? domainUrl + this.props.pageContext.canonicalPath
                : domainUrl + this.props.path;

        console.log('canonical', canonicalUrl);

        return (
            <>
                <Seo
                    title={metaTitle}
                    description={metaDescription}
                    canonical={canonicalUrl}
                    image={ogImage}
                    ogImage={ogImage}
                />
                <Mainnavigation />

                <main>
                    <Container maxWidth="ssm" fixed>
                        <Box sx={{ width: '100%' }} mt="3%">
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                style={{ margin: 'auto' }}
                            >
                                <Grid item sx={{ flexGrow: 1 }}>
                                    <section className="Mobile:p-4 BigScreen:w-1/2 BigScreen:mx-auto">
                                        <div className="flex justify-between">
                                            <button className="backBtn" onClick={() => navigate(-1)}>
                                                <Back />
                                                <span style={{ lineHeight: '24px', marginLeft: '3px' }}>Back</span>
                                            </button>
                                            {post.headerImage && share && (
                                                <button onClick={handleShareBtn} className="backBtn shrBtn">
                                                    <Share />
                                                </button>
                                            )}
                                        </div>
                                        {post.headerImage && (
                                            <div className="h-auto relative">
                                                {/* <img
                                                    className="img"
                                                    src={post.headerImage.file.url}
                                                    alt={post.articleTitle}
                                                /> */}
                                                <GatsbyImage
                                                    image={getImage(post.headerImage)}
                                                    alt={post.deviceTitle}
                                                />
                                                <div className="mb-4">
                                                    <h1 className="articleTitle">{post.articleTitle}</h1>
                                                    <span>
                                                        Published: {date.toLocaleDateString('en-US', dateOptions)}
                                                    </span>
                                                    <br />
                                                </div>
                                            </div>
                                        )}
                                    </section>
                                    {post.headerImage ? (
                                        <>
                                            <div>
                                                {post.articleDetail1 && (
                                                    <Box sx={{ fontSize: '16px' }}>
                                                        {post.articleDetail1 &&
                                                            renderRichText(post.articleDetail1, options)}
                                                    </Box>
                                                )}
                                                {post.articleDetail2 && (
                                                    <Box sx={{ fontSize: '16px' }}>
                                                        {post.articleDetail2 &&
                                                            renderRichText(post.articleDetail2, options)}
                                                    </Box>
                                                )}
                                                {post.articleDetail3 && (
                                                    <Box sx={{ fontSize: '16px' }}>
                                                        {post.articleDetail3 &&
                                                            renderRichText(post.articleDetail3, options)}
                                                    </Box>
                                                )}
                                            </div>
                                            <MiniAdTemplate>
                                                As an Asurion Mobile+ member you get access to hundreds of articles,
                                                videos and virtual / personal assistance.{' '}
                                                <a href="https://www.asurion.com.au" className="linkArticle">
                                                    Find out more
                                                </a>
                                            </MiniAdTemplate>
                                            <br />
                                            <br />
                                        </>
                                    ) : (
                                        <p>Coming Soon...</p>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </main>
                <Footer />
            </>
        );
    }
}

export default SinglePageTemplate;

export const pageSinglePage = graphql`
    query singlePageQuery($slug: String) {
        appleArticleData: contentfulArticleTemplateApple(articleTitle: { eq: $slug }) {
            articleTitle
            articleUrl
            articleVisibility
            deviceTitle
            articleDetail1 {
                raw
            }
            articleDetail2 {
                raw
            }
            articleDetail3 {
                raw
            }
            articleImage {
                file {
                    url
                }
            }
            articleShortDescription {
                raw
            }
            headerImage {
                file {
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, width: 700)
            }
            publishedDate
            metaTags {
                description
                canonical
                hrefLang
                title
                robots
                ogImage {
                    file {
                        url
                        fileName
                    }
                }
            }
        }
        samsungArticleData: contentfulArticleTemplateSamsung(articleTitle: { eq: $slug }) {
            articleTitle
            articleUrl
            articleVisibility
            articleDetail1 {
                raw
            }
            articleDetail2 {
                raw
            }
            articleImage {
                file {
                    url
                }
            }
            articleShortDescription {
                raw
            }
            deviceTitle
            headerImage {
                file {
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, width: 700)
            }
            publishedDate
            metaTags {
                description
                canonical
                hrefLang
                title
                robots
                ogImage {
                    file {
                        url
                        fileName
                    }
                }
            }
        }
    }
`;
